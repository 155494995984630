<template>
  <div>
    <c-table
      ref="table"
      title="관련 교육 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskEducation',
  props: {
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "educationName",
            field: "educationName",
            label: "교육명",
            style: 'width:250px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            style: 'width:200px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationDate",
            field: "educationDate",
            label: "교육일",
            style: 'width:200px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTime",
            field: "educationTime",
            label: "교육시간",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            label: "교육종류1",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            label: "교육종류2",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            label: "교육구분",
            align: "center",
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            style: 'width:90px',
            label: "교육방법",
            align: "center",
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            style: 'width:200px',
            label: "학습목적",
            align: "left",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            style: 'width:200px',
            label: "관련법규",
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if (this.editable && !this.disabled) {
        numHeight = numHeight - 70
      }
      return numHeight + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.edu.result.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        riskAssessmentPlanId: this.param.riskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "교육계획 및 결과 상세"; // 교육계획 상세
      this.popupOptions.param = {
        plantCd: row.plantCd ? row.plantCd : this.searchParam.plantCd,
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.moc-timeline
  max-height: 550px
  .q-timeline__heading-title
    padding-bottom: 10px
</style>